
  import { Component, Vue, Prop } from "vue-property-decorator";
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";

  extend("required", { ...required, message: "{_field_} cannot be empty" });

  @Component({ components: { ValidationObserver, ValidationProvider } })
  export default class ProductCheckout extends Vue {
    @Prop({ default: null })
    price!: number | null;

    @Prop({ default: "" })
    name!: string;

    @Prop({ default: 1 })
    quantity!: number;

    @Prop({ default: false })
    showButton!: boolean;

    @Prop({ default: false })
    showRecertDisclaimer!: boolean;

    get loading() {
      return !this.price && !this.name;
    }

    formatMoney(amount: number | null) {
      return amount ? `$${amount.toFixed(2)}` : "$0.00";
    }
  }
