
  import { Component, Vue } from "vue-property-decorator";

  import { loadStripe } from "@stripe/stripe-js";
  import { api } from "@/api";
  import { readToken } from "@/store/main/getters";
  import { dispatchCheckApiError } from "@/store/main/actions";
  import { IProduct } from "@/interfaces";
  import { stripePublicKey } from "@/env";

  import ProductCheckout from "@/components/ProductCheckout.vue";

  const stripePromise = loadStripe(stripePublicKey);

  @Component({
    components: {
      ProductCheckout,
    },
  })
  export default class CheckoutMaterialsDownload extends Vue {
    product: IProduct | null = null;

    async redirectToStripeCheckout(stripeCheckoutSessionId: string) {
      const stripe = await stripePromise;
      if (stripe) {
        await stripe.redirectToCheckout({
          sessionId: stripeCheckoutSessionId,
        });
      }
    }

    public async mounted() {
      const token = readToken(this.$store);
      try {
        const resp = await api.getMaterialsDownloadProduct(token);
        this.product = resp.data;
      } catch (error) {
        this.product = null;
        dispatchCheckApiError(this.$store, error);
      }
    }

    async onSubmit() {
      const token = readToken(this.$store);
      try {
        const resp = await api.createMaterialsDownloadCheckoutSession(token);
        if (resp.data.stripe_checkout_session_id) {
          await this.redirectToStripeCheckout(resp.data.stripe_checkout_session_id);
        }
      } catch (error) {
        dispatchCheckApiError(this.$store, error);
      }
    }
  }
